import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import InventoryCarCardIcons from "../../shared/inventory-car-card-icons";
const ClpInventoryCarCardRevampBadges = ({label = "", value = ""}) => {

    return (
        <div styleName={`styles.badgeOuter ${label ? `styles.${label}` : ""}`}>
            <InventoryCarCardIcons label={label} />
            <p styleName={"styles.text"}>{value}</p>
        </div>
    );
};
ClpInventoryCarCardRevampBadges.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
};
export default ClpInventoryCarCardRevampBadges;
