/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import DISCOUNT_ICON from "../images/car-card-icons/discount.svg";
import VIEWED_ICON from "../images/car-card-icons/viewed.svg";
import SOLD_ICON from "../images/car-card-icons/sold.svg";
import RESERVED_ICON from "../images/car-card-icons/lock.svg";
import { INVENTORY_CAR_CARD_LABELS } from "../../../constants/app-constants";
const InventoryCarCardIcons = ({ label, height, width }) => {
    let icon = null;
    if (label === INVENTORY_CAR_CARD_LABELS.DISCOUNT) {
        icon = DISCOUNT_ICON;
    }
    if (label === INVENTORY_CAR_CARD_LABELS.VIEWED) {
        icon = VIEWED_ICON;
    }
    if (label === INVENTORY_CAR_CARD_LABELS.SOLD) {
        icon = SOLD_ICON;
    }
    if (label === INVENTORY_CAR_CARD_LABELS.RESERVED) {
        icon = RESERVED_ICON;
    }
    return icon && <img src={icon} height={height} width={width} />;
};

InventoryCarCardIcons.propTypes = {
    label: PropTypes.any,
    height: PropTypes.any,
    width: PropTypes.any
};

export default InventoryCarCardIcons;
